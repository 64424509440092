import React from 'react'
import { css } from '@emotion/core'
import { Box } from '.'

export const NativeHTMLTags: React.SFC<{}> = (props) => {
  return (
    <Box
      css={css`
        h1 {
          font-size: 2em;
        }
        h2 {
          font-size: 1.5em;
        }
        p {
          margin-bottom: 20px;
        }
        ul {
          li {
            margin-bottom: 10px;
          }
        }
      `}
      {...props}
    />
  )
}
